@import url('https://fonts.googleapis.com/css?family=Outfit');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 13px;
}

body {
  font-family: 'Outfit', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* custom-scrollbar.css */
/* Add scrollbar styling here */
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #71717A;
  -webkit-text-fill-color: #71717a;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
  color: #71717a;
}

.button {
  position: relative;
  background: #452BDA;
  border: none;
  outline: none;
  cursor: pointer;
  width: auto;
}

.button:active,
.button:hover {
  background: #3217CB;
}

.button__text {
  color: #ffffff;
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.disable-scroll {
  overflow: hidden;
}

.loader {
  width: 100%;
  height: 100vh; 
  background-color: #5840DE; 
  overflow: hidden;
  position: relative;
  transition: height 0.5s ease;
}

.progress {
  height: 100%;
  background-color: #452BDA;
  transition: width 0.5s ease, opacity 0.5s ease; 
}

.completed {
  animation: collapseUp 0.5s ease forwards, fadeOut 0.5s ease forwards;
}

@keyframes collapseUp {
  to {
    height: 0;
  }
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}


